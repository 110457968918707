import ConfigStaging from './config.staging';
import ConfigDev from './config.development';
import ConfigProd from './config.production';

/**
 * Return environment configs
 * if no environment matched return prod config
 */
export default function(): ConfigI {
    switch (process.env.NODE_ENV) {
        case 'development' : return ConfigDev;
        case 'staging'     : return ConfigStaging;
    }

    return ConfigProd;
}

export interface ConfigI {
    baseApiUrl(): string;
    apiToken(): string;
    kdsBaseApiUrl(): string;
    // mixpanelToken(): string;
    // cache(): boolean;
    firebaseConfig(): FireBaseConfigI;
    firebaseDatabasePrefix(): string;
    firebaseMessagingDatabasePrefix(): string;
    authenticationUrl(): string;
    rollbarAccessToken(): string;
    // codepush(): CodePushConfigI;
    // featureFlags(): FeatureFlagsConfigI;
}
export interface CodePushConfigI {
    ios(): string;
    android(): string;
}
export interface FireBaseConfigI {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
}
export interface FeatureFlagsConfigI {
    showOrderMessages: boolean;
    showHqMessages: boolean;
    showAlertBanner: boolean;
}
