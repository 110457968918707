
import config from '@/services/config';
import { defineComponent } from 'vue';

export default defineComponent({
    data:()=>({
        settings: [{
            name: 'BUILD GUIDE',
            action: 'build-guide',
            show: false,
        }, {
            name: 'TEST PRINTER',
            action: 'test-printer',
            show: false,
        }, {
            name: 'TEST EPOS PRINTER',
            action: 'test-epos-printer',
            show: false,
        }, {
            name: 'PRINTER SETTINGS',
            action: 'printer-settings',
            show: false,
        }, {
            name: 'CHANGE KITCHEN',
            action: 'change-kitchen',
            show: true,
        }, {
            name: 'SIGN OUT',
            action: 'sign-out',
            show: false,
        }] as SettingsMenuI[],
        showMenu: false,
    }),
    computed:{
        visibleSettings():SettingsMenuI[] {
            return this.settings.filter( ({ show }) => show );
        }
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        doAction(action: string) {
            switch (action) {
                case 'build-guide': break;
                case 'test-printer': break;
                case 'test-epos-printer': break;
                case 'printer-settings': break;
                case 'change-kitchen': this.changeKitchen(); break;
                case 'sign-out': break;
            }
        },
        changeKitchen(): void{
            window.open(config().authenticationUrl(), '_self');
        },
    }
});
interface SettingsMenuI {
    name: string;
    action: string;
    show: boolean;
}
