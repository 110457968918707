<template>
    <nav class="footer">
        <router-link
            class="footer__link"
            v-for="route of routes"
            :key="route.text"
            :to="route.to"
        >{{ route.text }}</router-link>
    </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    data:()=>({
        routes: [{
            text: 'Pre-Bake',
            to: '/pre-bake'
        }, {
            text: 'Post-Bake',
            to: '/post-bake'
        }, {
            text: 'Orders',
            to: '/orders'
        }]
    })
})
</script>

<style scoped>
.footer {
    background-color: var(--color-black);
    text-align: center;
}

.footer__link {
    color: var(--color-white);
    text-decoration: none;
    width: 108px;
    font-size: 14px;
    line-height: 60px;
    display: inline-block;
}

.router-link-active {
    background-color: var(--color-mine-shaft);
}
</style>
