import useConfig from '@/services/config';
import Axios, { AxiosRequestConfig } from 'axios';

export class XhrClient {
    public get baseUrl(): string {
        return `${useConfig().baseApiUrl()}${this.basePath}`;
    }

    public get basePath(): string {
        return '/webapi/v100';
    }

    public get(url: string, params?: any, header?: { [key: string]: any }): Promise<any> {
        return this.request('GET', url, params, header);
    }

    public post(url: string, params?: any, header?: { [key: string]: any }): Promise<any> {
        return this.request('POST', url, params, header);
    }

    public put(url: string, params?: any, header?: { [key: string]: any }): Promise<any> {
        return this.request('PUT', url, params, header);
    }

    public delete(url: string, params?: any, header?: { [key: string]: any }): Promise<any> {
        return this.request('DELETE', url, params, header);
    }

    /**
     *
     * @param method request method to use
     * @param url request url
     * @param params object used for query string or request body
     * @param header Header Overwrites, exp: checking shop details requires the cart endpoint to be called with a x-active-shop for that shop, but does not mean we should change in local storage
     */
    private async request(
        method: 'GET' | 'POST' | 'PUT' | 'DELETE',
        url: string,
        params: { [key: string]: any },
        header?: { [key: string]: string | number }
    ): Promise<any> {
        const headers = await this.getheaders(header);
        const options: AxiosRequestConfig = {
            url,
            method,
            baseURL: this.baseUrl,
            headers,
        };

        // determine if data should be pass to body or search attribute
        if (method.includes('GET')) {
            options.params = params
        } else {
            options.data = params
        }

        return Axios(options)
            .then( ({data}) => data );
    }

    // eslint-disable-line
    protected async getheaders(headerOverwrite: { [key: string]: any } = {}): Promise<any> {
        return {}
    }

    public getXhrHeaderClientKey() {
        return 'NextGenOnline'; //'FamKdsBumpScreen';
    }
}

export interface ApiHeadersI {
    'Api-Token': string;
    'X-Client': string;
    'X-Referrer'?: string;
    'X-Active-shop'?: string;
    Authorization?: string;
}
