import { ConfigI, FireBaseConfigI } from '.'

const config: ConfigI = {
    baseApiUrl: (): string => 'https://api-staging.andpizza.com',
    kdsBaseApiUrl: (): string => 'https://kds-staging.andpizza.com',
    apiToken: (): string => 'SrM8gqYvLYOowhu0deSheJxCuWBX',
    // mixpanelToken: (): string => '',
    // cache: (): boolean => true,
    firebaseConfig: (): FireBaseConfigI => ({
        apiKey: 'AIzaSyAC65WPIK_ut4TvMDq5agnCFv6d4uv2vIk',
        authDomain: 'andpizza-nextgen.firebaseapp.com',
        databaseURL: 'https://andpizza-nextgen.firebaseio.com',
        projectId: 'andpizza-nextgen',
        storageBucket: 'andpizza-nextgen.appspot.com',
        messagingSenderId:'351865441376',
    }),
    firebaseDatabasePrefix: (): string => 'staging-pickup',
    firebaseMessagingDatabasePrefix: (): string => 'staging-conversations',
    authenticationUrl: (): string => 'https://kds-staging.andpizza.com/screens',
    rollbarAccessToken: (): string => '',
    // codepush: (): CodePushConfigI => ({
    //     ios: () => '',
    //     android: () => '',
    // }),
    // featureFlags: (): FeatureFlagsConfigI => ({}),
}

export default config;
