import Cookies from 'js-cookie';

export default {
    token(): string {
        return Cookies.get('bumpscreen_token') ?? '';
    },
    jwt(): JWtinterface | undefined {
        const token = this.token();
        if (!token) return;
        return JSON.parse(atob(token.split('.')[1]));
    },
    shop(): string | undefined {
        const token = this.token();
        if (!token) return '';
        const jwt = this.jwt();
        return jwt?.shop_id;
    },
    line_id(): number | undefined {
        const token = this.token();
        if (!token) return;
        const jwt = this.jwt();
        return jwt?.line_id;
    }
};

interface JWtinterface {
    iss: string;
    iat: number;
    exp: number;
    nbf: number;
    jti: string;
    sub: number;
    prv: string;
    client: string;
    aud: null;
    shop_id: string;
    line_id: number;
}
