<template>
    <Header/>
    <main>
        <router-view/>
    </main>
    <Footer/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { createStore } from '@/stores';
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';

export default defineComponent({
    components: {
        Header,
        Footer,
    },
    setup() {
        createStore();
    },
})
</script>
<style>
/* the following styles are considered global */
main {
    height: calc(100% - 135px);
    background-color: var(--color-silver);
}

* {
    box-sizing: border-box;
}

body,
#app,
html {
    margin: 0;
    height: 100%;
}

p {
    margin: 0;
}

.orders-list-body {
    padding: 26px 24px 40px;
    display: flex;
    align-items: flex-start;
    overflow-x: scroll;
    height: 100%;
}

.orders-nav + .orders-list-body {
    height: calc(100% - 65px);
}

hr{
    background-color: var(--color-dove-gray);
    height: 0.25px;
}

.tile--normal {
    background-color: var(--color-white) !important;
}

.tile--alert {
    background-color: var(--color-red) !important;
    color: var(--color-white);
}

.tile--warning {
    background-color: var(--color-gorse) !important;
}
</style>
