<template>
    <div class="settings__carrot" :class="showMenu && 'settings__carrot'" @click="toggleMenu()">
        <img src="@/assets/carrot.svg" alt="Open Settings">
    </div>
    <ul class="settings__menu" v-if="showMenu">
        <li class="settings__menu-item"
            v-for="setting of visibleSettings"
            :key="setting.name"
            @click="doAction(setting.action)"
            v-html="setting.name"
        />
    </ul>
</template>

<script lang="ts">
import config from '@/services/config';
import { defineComponent } from 'vue';

export default defineComponent({
    data:()=>({
        settings: [{
            name: 'BUILD GUIDE',
            action: 'build-guide',
            show: false,
        }, {
            name: 'TEST PRINTER',
            action: 'test-printer',
            show: false,
        }, {
            name: 'TEST EPOS PRINTER',
            action: 'test-epos-printer',
            show: false,
        }, {
            name: 'PRINTER SETTINGS',
            action: 'printer-settings',
            show: false,
        }, {
            name: 'CHANGE KITCHEN',
            action: 'change-kitchen',
            show: true,
        }, {
            name: 'SIGN OUT',
            action: 'sign-out',
            show: false,
        }] as SettingsMenuI[],
        showMenu: false,
    }),
    computed:{
        visibleSettings():SettingsMenuI[] {
            return this.settings.filter( ({ show }) => show );
        }
    },
    methods: {
        toggleMenu() {
            this.showMenu = !this.showMenu;
        },
        doAction(action: string) {
            switch (action) {
                case 'build-guide': break;
                case 'test-printer': break;
                case 'test-epos-printer': break;
                case 'printer-settings': break;
                case 'change-kitchen': this.changeKitchen(); break;
                case 'sign-out': break;
            }
        },
        changeKitchen(): void{
            window.open(config().authenticationUrl(), '_self');
        },
    }
});
interface SettingsMenuI {
    name: string;
    action: string;
    show: boolean;
}
</script>

<style scoped>

.settings__carrot {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--color-scorpion);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 100%;
}
.settings__menu {
    position: absolute;
    top: 66px;
    right: 12px;
    background-color: var(--color-scorpion);
    padding: 28px 18px 0;
    margin: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
}

.settings__menu-item {
    list-style: none;
    margin-bottom: 35px;
    cursor: pointer;
}
</style>
