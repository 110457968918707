import { ApiHeadersI, XhrClient } from './XhrClient';
import config from '@/services/config';
import cookieAuth from '@/services/cookie-auth';

export class KdsApiService extends XhrClient {
    protected baseParams: SinglePrintReqI = {
        extras: false,
    }

    public get baseUrl(): string {
        return `${config().kdsBaseApiUrl()}${this.basePath}`;
    }
    public get basePath(): string {
        return '/api';
    }
    /**
     * Print all chits and extras in order
     * @param orderID
     */
    bumpStart(orderID: number){
        return this.post('/bump-start', {orderID});
    }


    /**
     * Print all chits and extras in order
     * @param orderID
     */
    printChits(orderID: number){
        return this.post('/print/chits', {orderID});
    }

    /**
     * print specific pie chit of order
     * @param params
     */
    printChit(params: SinglePrintReqI){
        params = {...this.baseParams, ...params};
        return this.post('/print/chit', params);
    }

    /**
     * Print all labels for pie
     * @param orderID
     */
    printLabels(orderID: number){
        return this.post('/print/labels', {orderID});
    }

    printLabel(params: SinglePrintReqI){
        params = {...this.baseParams, ...params};
        return this.post('/print/label', params);
    }

    protected async getheaders(headerOverwrite: { [key: string]: any } = {} ): Promise<ApiHeadersI> {
        const authToken = cookieAuth.token();
        const activeShopID = cookieAuth.shop();

        const headerOptions: ApiHeadersI = {
            'Api-Token': config().apiToken(),
            'X-Client': this.getXhrHeaderClientKey(),
        };

        if (window.location && window.location.href) {
            headerOptions['X-Referrer'] = window.location.href;
        }

        if (authToken) {
            headerOptions['Authorization'] = `Bearer ${authToken}`;
        }

        if (activeShopID || headerOverwrite['X-Active-shop']) {
            // Header values have to be strings
            headerOptions['X-Active-shop'] = `${headerOverwrite['X-Active-shop'] ?? activeShopID}`;
        }
        return headerOptions;
    }

}


interface SinglePrintReqI {
    orderID?: number;
    pie?: number,
    extras?: boolean
}
