
import { defineComponent } from 'vue';
import { createStore } from '@/stores';
import Header from '@/components/Header/Header.vue';
import Footer from '@/components/Footer/Footer.vue';

export default defineComponent({
    components: {
        Header,
        Footer,
    },
    setup() {
        createStore();
    },
})
