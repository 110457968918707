<template>
    <div v-if="hasPiPrinting && hasPrintManager" class="warning">
        This shop is using PI printing and TABLET printing
    </div>
    <div v-if="!hasPiPrinting && hasPrintManager" class="warning">
        This shop is ONLY using TABLET printing
    </div>
    <nav class="header">
        <div class="header__left">
            <img class="header__logo" alt="logo" src="@/assets/logo.svg">
            <ServicePill />
        </div>
        <div class="header__center">
            <h1 class="header__title font-druk">{{ pageTitle }}</h1>
        </div>

        <div class="header__right">
            <Details
                :kds="kdsLineID"
                :name="name"
            />
            <SettingsMenu />
        </div>
    </nav>
</template>

<script lang="ts">
import ServicePill from './components/ServicePill.vue';
import Details from './components/Details.vue';
import SettingsMenu from './components/SettingsMenu.vue';
import { defineComponent, ref, inject, computed } from 'vue';
import { webApi } from '@/services/api';
import cookieAuth from '@/services/cookie-auth';
import { Store } from '@/stores'; // eslint-disable-line

export default defineComponent({
    components: { ServicePill, Details, SettingsMenu },
    setup() {
        const name = ref();
        webApi.getShop().then((shop)=>{ name.value = shop?.data?.name; });

        const store = inject('store') as Store;

        return {
            name,
            hasPiPrinting: computed(() => store.state.hasPiPrinting),
            hasPrintManager: computed(() => store.state.hasPrintManager),
        };
    },
    computed: {
        pageTitle(): string { return this.$route.meta.title },
        kdsLineID(): number|undefined { return cookieAuth.line_id() },
    },
});
</script>
<style scoped>
.warning {
    background-color: gold;
    padding: 12px 0;
    font-weight:bold;
    text-align:center;
}
.header {
    color: var(--color-white);
    background-color: var(--color-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    padding-left: 30px;
    padding-right: 17px;
}
.header__left,
.header__right {
    display: flex;
    align-items: center;
    width: 25%;
}
.header__right {
    justify-content: flex-end;
}
.header__logo  {
    margin-right: 20px;
}

.header__title {
    font-family: Druk;
    font-weight: var(--weight-bolder);
    font-size: 36px;
    margin: 0;
    text-transform: uppercase;
}
</style>
