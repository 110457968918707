
import { defineComponent } from 'vue';

export default defineComponent({
    data:()=>({
        routes: [{
            text: 'Pre-Bake',
            to: '/pre-bake'
        }, {
            text: 'Post-Bake',
            to: '/post-bake'
        }, {
            text: 'Orders',
            to: '/orders'
        }]
    })
})
