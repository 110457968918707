<template>
    <div class="service-pill">Service On</div>
</template>

<style scoped>
.service-pill {
    width: 103px;
    line-height: 26px;
    text-align: center;
    border: 1px solid var(--color-green);
    box-sizing: border-box;
    border-radius: 16px;
    font-weight: var(--weight-bold);
    font-size: 12px;
    color: var(--color-green);
}
</style>
