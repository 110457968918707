import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import cookie from '@/services/cookie-auth';
import config from '@/services/config';
import { activePreStation } from "@/services/station-tracker";
const routes: Array<RouteRecordRaw> = [{
    path: '/',
    redirect: '/orders',
}, {
    path: "/orders",
    name: "Orders",
    meta: { title: 'Orders' },
    component: () => import(/* webpackChunkName: "Orders" */ "@/views/Orders/Orders.vue"),
    children: [{
        path: '',
        redirect: '/orders/active', // default child path
    }, {
        path: "/orders/active",
        name: "OrdersActive",
        component: () => import(/* webpackChunkName: "OrdersActive" */ "@/views/Orders/views/OrdersActive.vue"),
    }, {
        path: "/orders/canceled",
        name: "OrdersCanceled",
        component: () => import(/* webpackChunkName: "OrdersCanceled" */ "@/views/Orders/views/OrdersCanceled.vue"),
    }, {
        path: "/orders/completed",
        name: "OrdersCompleted",
        component: () => import(/* webpackChunkName: "OrdersCompleted" */ "@/views/Orders/views/OrdersCompleted.vue"),
    }],
}, {
    path: "/post-bake",
    name: "PostBake",
    meta: { title: 'Post-Bake' },
    component: () => import(/* webpackChunkName: "PostBake" */ "@/views/PostBake/PostBake.vue"),
    children: [{
        path: '',
        redirect: '/post-bake/all', // default child path
    },{
        path: '/post-bake/:station',
        component: () => import(/* webpackChunkName: "PostBakeStation" */ "@/views/PostBake/views/PostBakeStation.vue"),
    }]
}, {
    path: "/pre-bake",
    name: "PreBake",
    meta: { title: 'Pre-Bake' },
    component: () => import(/* webpackChunkName: "PreBake" */ "@/views/PreBake/PreBake.vue"),
    children: [{
        path: '',
        redirect: '/pre-bake/all', // default child path
    },{
        path: '/pre-bake/:station',
        component: () => import(/* webpackChunkName: "PreBakeStation" */ "@/views/PreBake/views/PreBakeStation.vue"),
    }]
}];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const showAuthError = ()=> confirm('Could not authenticate with the server. press OK to Login')
    if (!cookie.token()) {
        showAuthError();
        location.href = config().authenticationUrl();
        return;
    }

    // Make sure the token hasn't expired
    const exp = cookie.jwt()?.exp ?? 0;
    if (exp < Math.floor(Date.now() / 1000)) {
        showAuthError();
        location.href = config().authenticationUrl();
        return;
    }

    // Route to active prebake station
    if (to?.meta?.title == 'Pre-Bake') {
        // If going to prebake section from non-prebake section, redirect to active station
        if (to?.params?.station == 'all' && from?.meta?.title != 'Pre-Bake' && activePreStation.value && to?.fullPath != activePreStation.value) {
            next({ path: activePreStation.value});
        }
        // Update active station
        activePreStation.value = to?.fullPath;
    }
    next();
})
export default router;
