import { webApi } from '@/services/api';
import { provide, reactive, readonly } from 'vue';

export interface Store {
    state: StoreStateI;
}
export interface StoreStateI {
    currentTime: Date;
    enableChitPrinting: boolean;
    ordersNav: StoreNavItemI[];
    preStations: StoreNavItemI[];
    postStations: StoreNavItemI[];
    allOrderStationsPreBake: string[];
    allOrderStationsPostBake: string[];
    hasPiPrinting: boolean;
    hasPrintManager: boolean;
}

export interface StoreNavItemI {
    text: string
    to: string;
    raw?: string;
}

export const createStore = (): void => {
    /**
     * Initialize the state object
     */
    const state: StoreStateI = reactive({
        currentTime: new Date(),
        enableChitPrinting: false,
        hasPiPrinting: false,
        hasPrintManager: false,
        ordersNav: [{
            text: 'Active',
            to: '/orders/active'
        }, {
            text: 'Canceled',
            to: '/orders/canceled'
        }, {
            text: 'Completed',
            to: '/orders/completed'
        }],
        preStations: [],
        postStations: [],
        allOrderStationsPreBake: [],
        allOrderStationsPostBake: [],
    });

    const storeKdsStateStations = (shop:ApiData.ShopI) => {
        if (shop?.kds_states?.pre_bake && shop?.kds_states?.pre_bake.length > 1) {
            state.preStations = shop?.kds_states?.pre_bake.map(stationNavItem.bind(undefined, '/pre-bake'));
        }
        if (shop?.kds_states?.post_bake && shop?.kds_states?.post_bake.length > 1) {
            state.postStations = shop?.kds_states?.post_bake.map(stationNavItem.bind(undefined, '/post-bake'));
        }
        // Add all view, if this is the only view it will hide nav bar
        state.preStations.push({ text: 'all', to: '/pre-bake/all', raw: 'all' });
        state.postStations.push({ text: 'all', to: '/post-bake/all', raw: 'all' });

        // store first and last stations, this is used to determine which views should show all stations
        state.allOrderStationsPreBake = setAllOrderStations(state.preStations)
        state.allOrderStationsPostBake = setAllOrderStations(state.postStations)
    };

    /**
     * sync has_(curbside|smart_pickup) with api
     * Only update values if they differ,
     * that is important since these values changes affects UI components and want to avoif rerenders
     */
    const syncShopData = async () =>{
        const shop = await webApi.getShop();
        state.preStations = [];
        state.postStations = [];

        storeKdsStateStations(shop.data);

        if (state.enableChitPrinting != shop.data?.enable_chit_printing) {
            state.enableChitPrinting = shop.data?.enable_chit_printing;
        }

        state.hasPiPrinting = shop.data?.print.has_pi_printing;
        state.hasPrintManager = shop.data?.print.has_print_manager;
    };
    const setAllOrderStations = (stationsVal: StoreNavItemI[] )=>{
        if (!stationsVal || stationsVal.length == 0) return [];
        return [
            stationsVal[0].raw as string,
            stationsVal[stationsVal.length-1].raw as string,
        ];
    }

    const stationNavItem = (base: string, item: string): StoreNavItemI => {
        return {
            to: `${base}/${item}`,
            text: item.replace(/-|_/g, ' '),
            raw: item,
        }
    };

    /*
    client.getConfigs().then((configs)=>{
        state.validClientPlatform = configs.data?.curbside?.tablet?.valid_client_platforms;
    }) */

    /**
     * Update the `currentTime` every five seconds
     */
    setInterval(() => state.currentTime = new Date(), 500);

    /**interval: 30 seconds */
    syncShopData();
    setInterval(() => syncShopData(), 30000);

    /**
     * Provide the store object for injection into individual components
     */
    provide('store', { state: readonly(state) } as Store);
};
