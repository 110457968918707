import { CodePushConfigI, ConfigI, FeatureFlagsConfigI, FireBaseConfigI } from '.'

const config: ConfigI = {
    baseApiUrl: (): string => 'https://api.andpizza.com',
    kdsBaseApiUrl: (): string => 'https://kds.andpizza.com',
    apiToken: (): string => 'SrM8gqYvLYOowhu0deSheJxCuWBX',
    // mixpanelToken: (): string => '',
    // cache: (): boolean => true,
    firebaseConfig: (): FireBaseConfigI => ({
        apiKey: 'AIzaSyC5ZkvTB46K9P2YBJ2tR2-L77G3mwhZhnI',
        authDomain: 'link.andpizza.com',
        databaseURL: 'https://andpizza-719a7.firebaseio.com',
        projectId: 'andpizza-nextgen',
        storageBucket: 'andpizza-719a7.appspot.com',
        messagingSenderId: '1044325405697'
    }),
    firebaseDatabasePrefix: (): string => 'nextgen-pickup',
    firebaseMessagingDatabasePrefix: (): string => 'nextgen-conversations',
    authenticationUrl: (): string => 'https://kds.andpizza.com/screens',
    rollbarAccessToken: (): string => '37259be4cbab472bb0199df18c9a30ea',
    // codepush: (): CodePushConfigI => ({
    //     ios: () => '',
    //     android: () => '',
    // }),
    // featureFlags: (): FeatureFlagsConfigI => ({
    //     showOrderMessages: false,
    //     showHqMessages: false,
    //     showAlertBanner: false,
    // }),
}

export default config;
