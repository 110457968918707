import { ApiHeadersI, XhrClient } from './XhrClient';

import cookieAuth from '@/services/cookie-auth';
import config from '../config';

export class ApiService extends XhrClient {
    /**
     * GET: /shops
     * GET: /shops/${shopID}/${method}
     * Get shop(s)
     * @param  {string} id [Leave empty to get all shops]
     * @return {Promise<ApiRes.ShopI>}
     */
    public getShop(): Promise<{success: boolean; data: ApiData.ShopI}> {
        const shopID = cookieAuth.shop();
        if (!shopID) Promise.reject();
        return this.get(`/shops/${shopID}`);
    }

    public getFirebaseToken(): Promise<FirebaseTokenI> {
        return this.get(`/curbside/firebase-token`).catch(()=>{
            confirm('Could not authenticate with the server. press OK to Login')
            location.href = config().authenticationUrl();
        });
    }
    public setPieKdsState(orderId: number, orderItemId: number, qtyOfItem: number, kds_state: string = 'post_bake') {
        return this.post(`orders/${orderId}/set-kds-state/${orderItemId}/${qtyOfItem}`, {kds_state});
    }
    public acknowledgeHandoff(orderId: number) {
        return this.post(`/orders/${orderId}/acknowledge-handoff`);
    }
    /**
     * setExtraKdsState
     */
    public setExtraKdsState(extras: FBorderI.OrderExtraI[], orderID: number) {
        const extrasRequest = extras.map(
            (extra) => this.setPieKdsState.apply(this, [orderID, extra.order_item_id, extra.qtyOfItem])
        );
        return Promise.all(extrasRequest);
    }

    protected async getheaders(headerOverwrite: { [key: string]: any } = {} ): Promise<ApiHeadersI> {
        const authToken = cookieAuth.token();
        const activeShopID = cookieAuth.shop();

        const headerOptions: ApiHeadersI = {
            'Api-Token': config().apiToken(),
            'X-Client': this.getXhrHeaderClientKey(),
        };

        if (window.location && window.location.href) {
            headerOptions['X-Referrer'] = window.location.href;
        }

        if (authToken) {
            headerOptions['Authorization'] = `Bearer ${authToken}`;
        }

        if (activeShopID || headerOverwrite['X-Active-shop']) {
            // Header values have to be strings
            headerOptions['X-Active-shop'] = `${headerOverwrite['X-Active-shop'] ?? activeShopID}`;
        }
        return headerOptions;
    }
}



interface FirebaseTokenI {
    success: boolean;
    data: {
        token: string;
    }
}
