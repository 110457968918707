
import ServicePill from './components/ServicePill.vue';
import Details from './components/Details.vue';
import SettingsMenu from './components/SettingsMenu.vue';
import { defineComponent, ref, inject, computed } from 'vue';
import { webApi } from '@/services/api';
import cookieAuth from '@/services/cookie-auth';
import { Store } from '@/stores'; // eslint-disable-line

export default defineComponent({
    components: { ServicePill, Details, SettingsMenu },
    setup() {
        const name = ref();
        webApi.getShop().then((shop)=>{ name.value = shop?.data?.name; });

        const store = inject('store') as Store;

        return {
            name,
            hasPiPrinting: computed(() => store.state.hasPiPrinting),
            hasPrintManager: computed(() => store.state.hasPrintManager),
        };
    },
    computed: {
        pageTitle(): string { return this.$route.meta.title },
        kdsLineID(): number|undefined { return cookieAuth.line_id() },
    },
});
