import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'

import '@/theme/fonts.css';
import '@/theme/variables.css';
import Rollbar from '@/services/rollbar';
createApp(App)
.use(router)
.use(Rollbar)
.mount('#app')
