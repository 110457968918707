<template>
    <div class="details">
        <img class="details__avatar" src="@/assets/avatar.svg">
        <div class="details__content">
            <p class="details__kds" v-if="kds">Line {{ kds }}</p>
            <p class="details__shop" v-if="name">{{ name }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        kds: Number,
        name: String,
    }
});
</script>

<style scoped>
.details {
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.details__avatar {
    margin-right: 7px;
}

.details__content p {
    margin: 0;
    font-weight: var(--weight-bold);
    font-size: 12px;
    line-height: 14px;
}
</style>
